<template>
  <div class="finanicalDetails"  >
    <div class="vx-row">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Bank Details" subtitle="" class="mb-base">
          <p>{{financialDetails.accountHolderName}}</p>
          <p>{{financialDetails.accountHolderEmail}}</p>

          <p>{{financialDetails.bsb}}/ {{financialDetails.accountNumber}}</p>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BankProfile",
    props:{
      financialDetails: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
