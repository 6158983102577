<template>
  <div class="vx-row">
    <div class="vx-col lg:w-1/2 w-full">
      <vx-card title="Card Details" subtitle="" >
        <p>{{cardDetails.brand}}</p>

        <p>XXXX XXXX XXXX {{cardDetails.last4}}</p>
        <p class="mb-4">{{ cardDetails.exp_month}} / {{ cardDetails.exp_year}}</p>
        <!--        <a @click="removeCard" class="remove-card" style="cursor:pointer;">Remove Card</a>-->
      </vx-card>
    </div>
  </div>
</template>

<script>

  import {mapActions} from "vuex";

  export default {
    name: "StripeCardDetails",
    props:['cardDetails'],
    methods:{
      ...mapActions("general", [
        "removeUserStripeCard"
      ]),
      removeCard(){
        let self = this;
        this.$vs.loading();

        this.$vs.loading.close();
        this.removeUserStripeCard().then((response) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: response.data.title,
            text: response.data.message,
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
          self.$emit('onRemoveCardSuccess')
        }).catch((error) =>{
          console.log(error);
          this.$vs.loading.close();
          this.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
        });

      }
    }
  }
</script>

<style scoped>

</style>
