<template>
  <div>
    <vs-card>
      <vs-row>
        <ul style="width: 100%">
          <li class="ml-10 mb-2">
            <h4>{{ message }}</h4>
          </li>
          <li>
            <div class="flex flex-wrap justify-between items-center">
              <vs-radio v-model="paymentType" vs-value="BankAccount" class="ml-10"
                >Bank Account<a
                  class="ml-3"
                  @click="isNewClinicBankAccount = !isNewClinicBankAccount"
                  v-if="paymentType == 'BankAccount' && financialDetails"
                  >Change</a
                ></vs-radio
              >
            </div>
          </li>
          <li class="mt-2 mb-2">
            <div class="flex flex-wrap justify-between items-center">
              <vs-radio v-model="paymentType" vs-value="CreditCard" class="ml-10"
                >Credit Card<a
                  class="ml-3"
                  @click="isNewCreditCard = !isNewCreditCard"
                  v-if="paymentType == 'CreditCard' && cardDetails"
                  >Change</a
                ></vs-radio
              >
            </div>
            <div v-if="paymentType == 'BankAccount' && isNewClinicBankAccount">
              <div
                style="width: 65%; margin-left: auto; margin-right: auto"
                class="text-center mt-5 mb-5"
              >
                <BankSetup />
              </div>
            </div>
            <div
              v-if="
                paymentType == 'BankAccount' &&
                !isNewClinicBankAccount &&
                financialDetails
              "
            >
              <div
                style="width: 65%; margin-left: auto; margin-right: auto"
                class="mt-5 mb-5"
              >
                <div class="sr-root">
                  <div class="stripe-main full-page-bg-color w-100" style="">
                    <div class="vx-col mx-auto self-center">
                      <div class="saved-card">
                        <h3>{{ financialDetails.accountHolderName }}</h3>
                        <h3>{{ financialDetails.accountHolderEmail }}</h3>

                        <h3>
                          {{ financialDetails.bsb }}/ {{ financialDetails.accountNumber }}
                        </h3>
                        <button
                          class="credit-card-submit"
                          @click="sendToServer({ defaultPaymentMethod: paymentType })"
                        >
                          <span class="payment">Confirm</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="stripe-root"
              v-if="paymentType == 'CreditCard' && isNewCreditCard"
            >
              <div
                style="width: 65%; margin-left: auto; margin-right: auto"
                class="text-center mt-5 mb-5"
              >
                <vs-alert :active.sync="active2" closable close-icon="close">
                  Credit Card has been set up successfully.
                </vs-alert>
                <div class="main mb-45 mt-30">
                  <div class="vx-card__title mt-30">
                    <div
                      style="width: 65%; margin-left: auto; margin-right: auto"
                      class="text-center mt-5 mb-5"
                    ></div>
                    <div class="sr-root">
                      <div class="stripe-main full-page-bg-color w-100" style="">
                        <div class="vx-col mx-auto self-center">
                          <stripe-elements
                            ref="elementsRef"
                            :pk="stripePublishableKey"
                            :amount="0"
                            locale="en"
                            @token="tokenCreated"
                            @loading="loading = $event"
                          >
                          </stripe-elements>
                          <button class="credit-card-submit" @click="stripeSubmit">
                            <span class="payment">Confirm</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            <div
              style="width: 65%; margin-left: auto; margin-right: auto"
              class="mt-5 mb-5"
              v-if="paymentType == 'CreditCard' && cardDetails && !isNewCreditCard"
            >
              <div class="sr-root">
                <div class="stripe-main full-page-bg-color w-100" style="">
                  <div class="vx-col mx-auto self-center">
                    <div class="saved-card">
                      <h3>{{ cardDetails.brand }}</h3>

                      <h3>XXXX XXXX XXXX {{ cardDetails.last4 }}</h3>
                      <h3 class="mb-4">
                        {{ cardDetails.exp_month }} / {{ cardDetails.exp_year }}
                      </h3>

                      <button
                        class="credit-card-submit"
                        @click="sendToServer({ defaultPaymentMethod: paymentType })"
                      >
                        <span class="payment">Confirm</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import StripeElements from "@/views/components/order-center/StripeElements";
import BankSetup from "./bankDetails/BankSetup"
import BankProfile from "../components/profile/BankProfile";
import StripeCardDetails from "../components/profile/StripeCardDetails";
import {mapActions} from "vuex";

export default {
  name: "ClinicPaymentMethodSetup",
  components:{
    StripeElements,
    BankProfile,
    BankSetup,
    StripeCardDetails
  },
  data(){
    return {
      stripePublishableKey:process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      paymentType:'BankAccount',
      userDetail: {},
      active2:false,
      isNewClinicBankAccount:true,
      isNewCreditCard:true,
      financialDetails:null,
      cardDetails:null,
      savedPaymentDetail: null
    }
  },
  methods:{
    ...mapActions("general", ["fetchCardDetails"]),
    ...mapActions("clinic", [
      "submitStepDefaultPayment"
    ]),
    async fetchCardDetail() {
      const cardDetails = await this.fetchCardDetails();
      this.cardDetails = cardDetails.data.data;
    },
    tokenCreated (token) {
      this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      const payload = {
        token: this.token.id,
        defaultPaymentMethod: this.paymentType
      };

      this.sendToServer(payload);
    },
    async sendToServer (payloadData) {
      var self =this;
      // Send to charge to your backend server to be processed
      this.$vs.loading();
      // Documentation here: https://stripe.com/docs/api/charges/create
      const payload = {
        userId: this.$store.state.AppActiveUser._id,
        body: payloadData
      };
      this.submitStepDefaultPayment(payload).then((response) => {
        this.$vs.notify({
          title: response.data.title,
          text: response.data.message,
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
        // this.active2 = true
        this.$vs.loading.close();
      }).catch((error) =>{
        this.$vs.loading.close();
        this.$vs.notify({
          title: error.data.title,
          text: error.data.message,
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
      });
    },
    stripeSubmit(){
      this.$refs.elementsRef.submit();
    },
    hasSetupBankDetails() {
      return (this.$store.state.AppActiveUser.paymentDetails &&
        this.$store.state.AppActiveUser.paymentDetails.customerId &&
        this.$store.state.AppActiveUser.paymentDetails.mandate &&
        this.$store.state.AppActiveUser.paymentDetails.mandate.payment_method &&
        this.$store.state.AppActiveUser.paymentDetails.mandate.id);
    },
    hasSetupCardDetails() {
      return (this.$store.state.AppActiveUser.paymentDetails &&
        this.$store.state.AppActiveUser.paymentDetails.customerId &&
        this.$store.state.AppActiveUser.paymentDetails.cardId);
    }
  },
  computed: {
    message() {
      if(this.$store.state.AppActiveUser && this.$store.state.AppActiveUser.defaultPaymentMethod)
        return `Your current default payment method is ${this.$store.state.AppActiveUser.defaultPaymentMethod}`;

      if(this.hasSetupBankDetails())
        return "Your current default payment method is Bank Account";

      if(this.hasSetupCardDetails())
        return "Your current default payment method is Credit Card";

      return "You haven't saved any of the payment methods yet !";
    }
  },
  created() {
    this.userDetail = this.$store.state.AppActiveUser;
    if (
      this.userDetail.paymentDetails &&
      this.userDetail.paymentDetails.cardId
    ) {
      this.isNewCreditCard = false
      this.fetchCardDetail();
    }
    if(this.userDetail.financialDetails){
      this.financialDetails = this.userDetail.financialDetails
      this.isNewClinicBankAccount = false;
    }

    if(this.$store.state.AppActiveUser.defaultPaymentMethod) {
      this.paymentType = this.$store.state.AppActiveUser.defaultPaymentMethod;
    }
  }
}
</script>

<style scoped>
.stripe-main {
  justify-content: center;
  height: 100%;
  width: 600px;
  min-width: 450px;
  align-self: center;
  padding: 75px 50px;
  background: rgb(247, 250, 252);
  border-radius: 6px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}
/* Buttons and links */
button {
  background: rgb(50, 50, 93);
  border-radius: 6px;
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}
/* Layout */
.sr-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 980px;
  align-content: center;
  justify-content: center;
  height: auto;
  margin: 0 auto;
}
</style>
